#nav {
    height: 10vh;

    #nav__top {
        #nav__logo {
            margin-left: 2.5rem;
        }
    }

    #nav__bottom {

        .nav__links-values,
        .nav__links-socials {
            cursor: pointer;
        }

        .nav__links-values::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            transform: scaleX(0);
            transition: transform 0.3s ease;
        }

        .nav__links-values:hover::before {
            transform: scaleX(1);
        }
    }
}

#sidebar {
    opacity: 0.4;

    #sidebar__links-container {
        width: 60%;
        border: 2px solid red;
    }

    .sidebar__menu {
        border: 2px solid red;
    }

}



#sidebar__icon {
    top: 3%;
    left: 3%;
    transition: all 0.3s ease;
}

#sidebar__icon-active {
    visibility: hidden;
}