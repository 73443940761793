#whatKeto {
    height: 100vh;

    #whatKeto__subtitle {
        width: 82%;
    }

    .whatKeto__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        transition: box-shadow 500ms;
        width: 85%;

        .whatKeto__icon-wrapper {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            height: 7rem;
            width: 7rem;
    
            #sugar {
                position: absolute;
            }
        }
        .whatKeto__text-wrapper {
            height: 100%;
        }
    }

    #whatKeto__img {
        height: 85%;
        position: absolute;
        top: 9%;
    }
}
