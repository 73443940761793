#location {
    height: 150vh;

    #location__hero {

        #location__titles {
            height: 80%;

            #location__hero__btn {
                height: 18%;
                width: 35%;
                border: none !important;
                display: inline-block;
                cursor: pointer;
                transition: all 0.4s ease-in;
            }
        }

        #location__heroImg-container {
            border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
            height: 80%;

            #location__heroImg {
                max-width: 70%;
                max-height: 70%;
            }
        }
    }

    #location__maps {
        #location__states {
            height: 10%;

            Form {
                .location__form-select {
                    width: 45%;
                    height: 55%;
                    border-top: none;
                    border-radius: 0pxh;
                }
            }
        }

        #location__info {
            height: 90%;
            position: relative;

            h5 {
                height: 12%;
            }

            #location__data {
                height: 88%;
                overflow-y: auto;
                margin-bottom: 10px;
            }
        }

        #location__card {
            z-index: 1;

            img {
                width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }

        .leaflet-container {
            width: 100%;
        }
    }
}