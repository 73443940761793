@mixin phone-breakpoint {
    @media only screen and (max-width: 600px){
        @content;
    }
};

@mixin tablet-breakpoint {
    @media only screen and (min-width: 600px){
        @content;
    }
};

@mixin laptop-breakpoint {
    @media only screen and (min-width: 900px){
        @content;
    }
};

@mixin desktop-breakpoint {
    @media only screen and (min-width: 1200px){
        @content;
    }
};