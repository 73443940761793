#home {
    height: 100vh;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.781),
        rgba(0, 0, 0, 0.788)),
        url("https://images.unsplash.com/photo-1559058789-672da06263d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2067&q=80");

    #home--divider-bottom {
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
        position: absolute;
        bottom: 0;

        svg {
            position: relative;
            display: block;
            width: calc(100% + 1.3px);
            height: 5rem;
        }
    }
}

