html {
    height: 100%;

    .App {
        min-height: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
    }
}


Button {
    border: none !important;
}

